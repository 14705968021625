export default [
    {
        name: 'pMDI',
        description: 'Pressurized Metered Dose Inhaler',
        image: 'device-1.svg',
        optimalMin: 20,
        optimalMax: 60,
        // resistanceFactor: 1,
        resistanceFactor: 0.70,
    },
    {
        name: 'SMI',
        description: 'Soft Mist Inhaler',
        image: 'device-2.svg',
        optimalMin: 20,
        optimalMax: 60,
        // resistanceFactor: 1,
        resistanceFactor: 0.70,
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'device-7.svg',
        optimalMin: 30,
        optimalMax: 90,
        // resistanceFactor: 0.83,
        resistanceFactor: 0.60,
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'device-6.svg',
        optimalMin: 30,
        optimalMax: 90,
        // resistanceFactor: 0.664,
        resistanceFactor: 0.48,
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'device-3.svg',
        optimalMin: 30,
        optimalMax: 90,
        // resistanceFactor: 0.664,
        resistanceFactor: 0.48,
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'device-5.svg',
        optimalMin: 30,
        optimalMax: 90,
        // resistanceFactor: 0.498,
        resistanceFactor: 0.38,
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'device-8.svg',
        optimalMin: 30,
        optimalMax: 90,
        // resistanceFactor: 0.332,
        resistanceFactor: 0.28,
    },
    {
        name: 'DPI',
        description: 'Dry Powder Inhaler',
        image: 'device-4.svg',
        optimalMin: 30,
        optimalMax: 90,
        // resistanceFactor: 0.166,
        resistanceFactor: 0.25,
    },
]

export const groups = [
    'pMDI',
    'SMI',
    'DPI',
]