<template>
  <div id="page-container">

    <div id="gate" v-if="!mobileCheck">
      <h1>Oups</h1>
      <p>
        Please use your smartphone for an<br>
        enhanced inhaling experience.
      </p>
    </div>

    <app-header></app-header>

    <div id="main-container">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>

  </div>
</template>

<script>
import AppHeader from '~/layouts/partials/AppHeader.vue'

export default {
  name: 'BaseLayout',
  components: {
    AppHeader,
  },

  data () {
    return {
      ua: null,
    }
  },

  computed: {
    mobileCheck() {
      return this.deviceType == "mobile"
    },
    deviceType() {
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(this.ua)) {
        return "tablet";
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          this.ua
        )
      ) {
        return "mobile";
      }
      return "desktop";
    }
  },

  mounted() {
    this.ua = navigator.userAgent
  },

}
</script>
