<template>
  <transition name="fade" mode="out-in">
    <router-view></router-view>
  </transition>
</template>

<style lang="scss">
@import './assets/scss/main.scss';
</style>

<script>
export default {
  name: 'App',

  created() {
    this.init()
  },

  mounted() {
    this.initListeners()
    this.resize()
  },

  methods: {
    async init() {
      await this.$store.dispatch('inhaler/fetchAudioStream')
    },

    initListeners() {
      window.addEventListener('resize', () => { this.resize() })
    },

    resize() {
      let vh = window.innerHeight
      let elContainer = document.querySelector('#main-container')
      elContainer.style.height = vh + 'px'
    },
  },
}
</script>
