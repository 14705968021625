<template>
  <header class="menu" :class="{opened: isOpened}">
    <div class="menu_toggle" @click="isOpened = !isOpened"></div>
    <div class="menu_overlay bg-primary text-center">
      <router-link class="text-white" to="/landing" @click.native="isOpened=false">Home</router-link>
      <router-link class="text-white" to="/about" @click.native="isOpened=false">About this app</router-link>
      <router-link class="text-white" to="/terms" @click.native="isOpened=false">Terms of use</router-link>
      <router-link class="text-white" to="/privacy" @click.native="isOpened=false">Privacy policy</router-link>
    </div>
  </header>
</template>

<script>
export default {
  data () {
    return {
      isOpened: false,
    }
  },
}
</script>

<style lang="scss">
.menu {
  &_toggle {
    position: fixed;
    z-index: 300;
    top: 0;
    right: 0;
    width: 5rem;
    height: 5rem;
    
    opacity: 1 !important;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      width: 1.8rem;
      height: 5px;
      border-radius: 5px;
      background-color: #1a1e32;
      left: 50%;
      transition: background-color 0.35s, transform 0.2s cubic-bezier(.5,0,.5,1), top 0.2s cubic-bezier(.5,0,.5,1);
    }
    &:before {
      top: calc(50% - 5px);
    }
    &:after {
      top: calc(50% + 5px);
    }
  }
  &_overlay {

    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 5rem;
    transform: translateX(100%);
    transition: transform 0.35s cubic-bezier(.5,0,.5,1);

    a {
      display: block;
      font-size: 1.5rem;
      font-weight: 600;
      margin: 3rem 1rem;
      text-decoration: none !important;
    }
  }

  &.opened {
    .menu_toggle {
      &:before {
        background-color: white;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        background-color: white;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    .menu_overlay {
      transform: translateX(0);
    }
  }
}
</style>