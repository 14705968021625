/* eslint-disable */

import * as types from '../mutation-types'
import axios from 'axios'
import Devices from '~/data/devices.js'

// state
export const state = {
  flow: 0,
  maxFlow: 0,
  ambient: 0,
  selection: null,
  devices: Devices,
  stream: null,
}

// getters
export const getters = {
  flow: state => state.flow,
  maxFlow: state => state.maxFlow,
  ambient: state => state.ambient,

  resistanceFactor: (state, getters) => (getters.selectedDevice) ? getters.selectedDevice.resistanceFactor : 1,

  flowDampened: (state, getters) => Math.floor(getters.flow * getters.resistanceFactor),
  maxFlowDampened: (state, getters) =>  Math.floor(getters.maxFlow * getters.resistanceFactor),
  ambientDampened: (state, getters) =>  Math.floor(getters.ambient * getters.resistanceFactor),

  devices: state => state.devices,
  selectedDevice: (state) => {
    return state.selection != null ? state.devices[state.selection] : null
  },
  result: (state, getters) => {
    const selectedDevice = getters.selectedDevice
    return (selectedDevice && getters.maxFlow >= selectedDevice.optimalMin && getters.maxFlow <= selectedDevice.optimalMax)
  },
  checkPermission: state => state.stream,
  stream: state => state.stream,

  isPWA: () => {
    return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://')
  },

}

// mutations
export const mutations = {
  [types.SET_SELECTION] (state, selection) {
    state.selection = selection
  },
  [types.SET_FLOW] (state, flow) {
    state.flow = flow
    state.maxFlow = Math.max(state.flow, state.maxFlow)
  },
  [types.CLEAR_FLOW] (state) {
    state.flow = 0
    state.maxFlow = 0
  },
  [types.SET_RESISTANCE_FACTOR] (state, resistanceFactor) {
    if (state.selection != null) state.devices[state.selection].resistanceFactor = resistanceFactor
  },
  [types.SET_AMBIENT] (state, ambient) {
    state.ambient = ambient
  },
  [types.FETCH_AUDIO_STREAM] (state, stream) {
    state.stream = stream
  },
}

// actions
export const actions = {
  setSelection ({ commit }, payload) {
    commit(types.SET_SELECTION, payload)
  },
  setFlow ({ commit }, payload) {
    commit(types.SET_FLOW, payload)
  },
  clearFlow ({ commit }) {
    commit(types.CLEAR_FLOW)
  },
  setResistanceFactor ({ commit }, payload) {
    commit(types.SET_RESISTANCE_FACTOR, payload)
  },
  setAmbient ({ commit }, payload) {
    commit(types.SET_AMBIENT, payload)
  },
  async fetchAudioStream ({ commit }, payload) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({audio:true});
      // navigator.getUserMedia(
      //     {
      //         "audio": {
      //             "mandatory": {
      //                 "googEchoCancellation": "false",
      //                 "googAutoGainControl": "false",
      //                 "googNoiseSuppression": "false",
      //                 "googHighpassFilter": "false"
      //             },
      //             "optional": []
      //         },
      //     },
      //     onMicrophoneGranted,
      //     onMicrophoneDenied
      // );
      commit(types.FETCH_AUDIO_STREAM, stream)
    } catch(error) {
      console.log(error);
    }
  },
}
