// Main layouts
import LayoutSimple from '@/layouts/variations/Simple.vue'

const Landing = () => import("@/views/Landing.vue")
const Start = () => import("@/views/Start.vue")
const Main = () => import("@/views/Main.vue")
const MainCountdown = () => import("@/views/main/Countdown.vue")
const MainInhaler = () => import("@/views/main/Inhaler.vue")
const MainResult = () => import("@/views/main/Result.vue")
const Share = () => import("@/views/Share.vue")
const ShareConfirmation = () => import("@/views/ShareConfirmation.vue")
const About = () => import("@/views/About.vue")
const Terms = () => import("@/views/Terms.vue")
const Privacy = () => import("@/views/Privacy.vue")

export default [
  {
    path: '/',
    redirect: '/landing',
    component: LayoutSimple,
    children: [
      {
        path: '/landing',
        name: 'Landing',
        component: Landing
      },
      {
        path: '/start',
        name: 'Start',
        component: Start
      },
      {
        path: '/main',
        name: 'main',
        redirect: '/main/countdown',
        component: Main,
        children: [
          {
            path: 'countdown',
            name: 'Countdown',
            component: MainCountdown
          },
          {
            path: 'inhaler',
            name: 'Inhaler',
            component: MainInhaler
          },
          {
            path: 'result',
            name: 'Result',
            component: MainResult
          },
        ]
      },
      {
        path: '/share',
        name: 'Share',
        component: Share
      },
      {
        path: '/shareConfirmation',
        name: 'ShareConfirmation',
        component: ShareConfirmation
      },
      {
        path: '/about',
        name: 'About',
        component: About
      },
      {
        path: '/terms',
        name: 'Terms',
        component: Terms
      },
      {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
      },
    ]
  },
]
